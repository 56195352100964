'use strict'

angular.module('sale-entries').controller('CreateSaleEntryController', [
  '$scope',
  'products',
  '$timeout',
  'SlugService',
  '$http',
  'RemoteServer',
  'ErrorHandler',
  '$ionicLoading',
  'Authentication',
  '$ionicActionSheet',
  '$filter',
  'CustomerService',
  '$ionicModal',
  function (
    $scope,
    products,
    $timeout,
    SlugService,
    $http,
    RemoteServer,
    ErrorHandler,
    $ionicLoading,
    Authentication,
    $ionicActionSheet,
    $filter,
    CustomerService,
    $ionicModal
  ) {
    let vm = this

    vm.products = products
    vm.typeAheadProducts = []

    vm.clearProductSearch = function () {
      vm.productSearch = ''
    }

    vm.productSearchChanged = function (text) {
      //Return if user only type in 2 character or less
      if (!vm.productSearch || vm.productSearch.length <= 2) return
      var productLatinDescription = SlugService.getSlug(vm.productSearch).trim()
      let elements = productLatinDescription.split(' ')

      var products = _(vm.products)
        .map((product) => {
          let matchElements = product.latinDescription.split(' ')
          let score = _.sumBy(elements, (elm) => {
            return product.latinDescription.indexOf(elm) > -1 ? elm.length : 0
          })
          if (product.latinDescription.indexOf(productLatinDescription) > -1)
            score = score + productLatinDescription.length

          let sku = (product.sku || '').toString().toLowerCase()
          let oldSku = (product.oldSku || '').toString().toLowerCase()
          let barcode = (product.barcode || '').toString().toLowerCase()

          if (sku.indexOf(productLatinDescription) > -1)
            score = score + productLatinDescription.length * 4
          if (barcode.indexOf(productLatinDescription) > -1)
            score = score + productLatinDescription.length * 3
          if (barcode.indexOf(productLatinDescription) > -1)
            score = score + productLatinDescription.length * 2

          return _.assign({}, product, { score })
        })
        .filter((product) => product.score > 0)
        .orderBy(['score'], ['desc'])
        .take(20)
        .value()
      vm.typeAheadProducts = products
    }

    vm.addProductToOrder = function (item) {
      var matchItemIndex = _.findIndex($scope.data.items, { sku: item.sku })
      if (
        matchItemIndex > -1 &&
        !$scope.data.items[matchItemIndex].toLock &&
        !$scope.data.items[matchItemIndex].addGiftItem
      ) {
        $scope.data.items[matchItemIndex].quantity++
        item.quantity = $scope.data.items[matchItemIndex].quantity
        vm.calculate()
      } else {
        var newItem = {}
        if (item) {
          newItem = _.assign({}, item, {
            product: item._id,
            quantity: 1,
            originalPrice: item.price,
            soldPrice: item.salePrice ? item.salePrice : item.price,
            onSale: item.salePrice ? true : false,
            lineTotal: item.price,
            originalSalaryPercentage: item.salaryPercentage || 1,
            salaryPercentage: item.salaryPercentage || 1,
          })
          item.quantity = 1
          $scope.data.items.push(newItem)
        }
      }

      vm.calculate()
      $ionicLoading.show({
        template: 'Đã thêm. Tổng tiền: ' + $filter('number')($scope.data.saleTotal, 0),
      })
      $timeout(() => {
        $ionicLoading.hide()
      }, 500)
    }

    vm.calculate = () => {
      // Reset to base price
      _.forEach($scope.data.items, (item) => {
        if (item.addGiftItem || item.toLock) return
        if (item.quantity < 0) item.quantity = -item.quantity
        if (!item.old) {
          item.soldPrice = item.salePrice || item.originalPrice
          item.onSale = item.salePrice ? true : false
          item.salaryPercentage = item.originalSalaryPercentage
        }
      })

      const isTTClass125g = (item) =>
        item.description.match(/suất học.*(bánh dẻo|bánh nướng).*125g/i)
      const isTTClass = (item) => item.description.match(/suất học.*(bánh dẻo|bánh nướng).*/i)

      // Handle Sale Trung Thu class after 18:00pm
      let now = moment()
      if (now.hour() >= 18) {
        _.forEach($scope.data.items, (item) => {
          if (isTTClass(item)) {
            _.assign(item, { soldPrice: 85000, onSale: true })
          }
        })
      }

      // Handle Mooncake gift
      let numberOfMooncake125gProducts = _.reduce(
        $scope.data.items,
        (result, item) => (isTTClass125g(item) ? result + item.quantity || 0 : result),
        0
      )

      if (numberOfMooncake125gProducts >= 2) {
        let index = _.findIndex(
          $scope.data.items,
          (item) =>
            item.description.match(/Bộ túi & hộp giấy đựng 4 bánh Trung Thu 125-150g/i) &&
            item.quantity <= 2 &&
            item.price === 24000
        )
        if (index > -1) {
          $scope.data.items[index].soldPrice = 0
          $scope.data.items[index].onSale = true
        }
      }

      vm.invalidMessage = ''
      const getNumberOfItemsMatch = _.curry((items, regex) =>
        _.reduce(
          $scope.data.items,
          (result, item) => (item.description.match(regex) ? result + item.quantity || 0 : result),
          0
        )
      )

      const numberOfKitTTNuong125g = getNumberOfItemsMatch(
        $scope.data.items,
        /kit trung thu.*nướng.*125g/i
      )
      const numberOfKitTT75g = getNumberOfItemsMatch($scope.data.items, /kit trung thu.*75g/i)

      const numberOfKitTTNuong = numberOfKitTTNuong125g + numberOfKitTT75g
      const numberOfKitTTDeo = getNumberOfItemsMatch(
        $scope.data.items,
        /kit trung thu \d* bánh dẻo (tuyết|truyền thống) (50|125)g/i
      )

      const NHAN_NHUYEN_200G = /nhân trung thu.*200g/i
      const NHAN_THAP_CAM_600G = /nhân trung thu thập cẩm 600g/i
      const NHAN_THAP_CAM_150G = /nhân trung thu thập cẩm 150g/i

      const numberOfNhanNhuyen = getNumberOfItemsMatch($scope.data.items, NHAN_NHUYEN_200G)
      const numberOfNhanThapCam600g = getNumberOfItemsMatch($scope.data.items, NHAN_THAP_CAM_600G)
      const numberOfNhanThapCam150g = getNumberOfItemsMatch($scope.data.items, NHAN_THAP_CAM_150G)

      if (
        numberOfNhanNhuyen * 3 + numberOfNhanThapCam600g * 9 < numberOfKitTTNuong * 9 ||
        numberOfNhanNhuyen * 3 + numberOfNhanThapCam150g * 2 < numberOfKitTTDeo * 6 ||
        numberOfNhanNhuyen * 3 + numberOfNhanThapCam600g * 9 + numberOfNhanThapCam150g * 2 <
          numberOfKitTTNuong * 9 + numberOfKitTTDeo * 6
      )
        vm.invalidMessage = 'Chưa đủ nhân Trung Thu'
      else {
        let discountedNhan = 0
        _.forEach($scope.data.items, (item, index) => {
          if (
            item.description.match(NHAN_NHUYEN_200G) &&
            discountedNhan + item.quantity * 3 <= numberOfKitTTNuong * 9 + numberOfKitTTDeo * 6
          ) {
            _.assign($scope.data.items[index], { soldPrice: 0 })
            discountedNhan += item.quantity * 3
          }
          if (
            item.description.match(NHAN_THAP_CAM_150G) &&
            discountedNhan + item.quantity * 2 <= numberOfKitTTNuong * 9 + numberOfKitTTDeo * 6
          ) {
            _.assign($scope.data.items[index], { soldPrice: 20000, onSale: true })
            discountedNhan += item.quantity * 2
          }
          if (
            item.description.match(NHAN_THAP_CAM_600G) &&
            discountedNhan + item.quantity * 9 <= numberOfKitTTNuong * 9 + numberOfKitTTDeo * 6
          ) {
            _.assign($scope.data.items[index], { soldPrice: 70000, onSale: true })
            discountedNhan += item.quantity * 9
          }
        })
      }
      // Handle bundle skus
      _.forEach($scope.data.items, (item, index) => {
        if (item.addGiftItem || item.toLock) return
        if (item.bundlePrices && item.bundlePrices.length) {
          let matchBundlePrice = _.find(
            item.bundlePrices,
            (bundlePrice) => item.quantity >= bundlePrice.quantity
          )
          if (matchBundlePrice) {
            let normalPriceQuantity = item.quantity % matchBundlePrice.quantity
            if (normalPriceQuantity)
              $scope.data.items.splice(
                index + 1,
                0,
                _.assign({}, item, { quantity: normalPriceQuantity })
              )

            let offPriceQuantity =
              Math.floor(item.quantity / matchBundlePrice.quantity) * matchBundlePrice.quantity
            item.soldPrice = matchBundlePrice.price
            item.quantity = offPriceQuantity
            item.onSale = true
            item.toLock = true
          }
        }
      })

      vm.subTotal = 0
      $scope.data.items.forEach(function (item) {
        item.lineTotal = item.soldPrice * item.quantity
        vm.subTotal += item.lineTotal
      })

      vm.saleTotal = vm.subTotal

      $scope.data.items = $scope.data.items
      $scope.data.saleTotal = vm.subTotal
    }

    vm.increaseQuantity = (item) => {
      item.quantity = item.quantity + 1
      vm.calculate()
    }

    vm.decreaseQuantity = (item) => {
      item.quantity = item.quantity - 1
      if (item.quantity <= 0) {
        let index = _.findIndex($scope.data.items, { sku: item.sku })
        $scope.data.items.splice(index, 1)
      }
      vm.calculate()
    }

    vm.clearItems = () => {
      $scope.data.items = []
      vm.calculate()
    }

    vm.clearCustomer = () => {
      $scope.data.customer = null
    }

    const sendSaleEntryToOutlet = (outletCode) => {
      if (vm.sending) return
      const items = $scope.data.items
      const customer = $scope.data.customer
      vm.sending = true
      $timeout(() => {
        $http
          .post(RemoteServer.getRemoteServerAddress() + '/api/sale-entries/mobile', {
            outletCode,
            items,
            customer,
          })
          .then((reponse) => {
            vm.sending = false
            alert('Ok!')
          })
          .catch((err) => {
            vm.sending = false
            ErrorHandler.alertServerErrorMessage(err)
          })
      }, 350)
    }

    vm.sendSaleEntry = () => {
      const user = Authentication.user

      if (user.roles.indexOf('admin') > -1) {
        let hideSheet = $ionicActionSheet.show({
          buttons: [{ text: 'M1' }, { text: 'M2' }],
          titleText: 'Choose outlet',
          cancelText: 'Cancel',
          cancel: function () {
            //NO-OP
          },
          buttonClicked: function (index) {
            if (index === 0) sendSaleEntryToOutlet('M1')
            if (index === 1) sendSaleEntryToOutlet('M2')
            hideSheet()
          },
        })
      } else {
        if (!user.outlet) return ErrorHandler.allertMessage('Bạn không thuộc về một cơ sở nào.')
        const outletCode = user.outlet.code
        sendSaleEntryToOutlet(outletCode)
      }
    }

    vm.clearCustomerSearch = () => {
      vm.customerSearch = null
    }

    vm.searchCustomers = (searchText) => {
      CustomerService.searchCustomers(searchText).then((customers) => {
        vm.customers = customers
      })
    }

    vm.addCustomerToOrder = (customer) => {
      $scope.data.customer = customer
      $ionicLoading.show({
        template: 'Đã chọn khách hàng!',
      })
      $timeout(function () {
        $ionicLoading.hide()
      }, 350)
    }

    $ionicModal
      .fromTemplateUrl('templates/customers/create-customer-modal.template.html', {
        scope: $scope,
      })
      .then(function (modal) {
        vm.createCustomerModal = modal
      })

    vm.createCustomer = (customerInfo) => {
      vm.submittingCustomer = true

      if (!vm.customerDistrict) {
        $scope.submitting = false
        return ErrorHandler.allertMessage('Bạn chưa điền quận!')
      }

      $http
        .post(RemoteServer.getRemoteServerAddress() + '/api/customers', {
          fullName: vm.customerFullName,
          cellphone: vm.customerSearch,
          district: vm.customerDistrict,
        })
        .then((response) => response.data)
        .then((customer) => {
          $scope.data.customer = customer
        })
        .then(() => vm.createCustomerModal.hide())
        .catch(ErrorHandler.alertServerErrorMessage)
    }
  },
])
