'use strict'

angular
  .module('inventory-counts')

  .factory('InventoryCounts', [
    '$http',
    '$q',
    'RemoteServer',
    'Authentication',
    'ErrorHandler',
    '$state',
    '$localStorage',
    function ($http, $q, RemoteServer, Authentication, ErrorHandler, $state, $localStorage) {
      let inventoryCountPromise = $q.resolve(null)
      function setItemType(item) {
        if (!item || !_.isFinite(item.countQuantity)) {
          _.set(item, 'counting', true)
        } else {
          _.set(item, 'counting', false)
        }
        if (_.isFinite(item.countQuantity) && item.countQuantity === item.expectedQuantity) {
          _.set(item, 'matched', true)
        } else {
          _.set(item, 'matched', false)
        }
        if (_.isFinite(item.countQuantity) && item.countQuantity !== item.expectedQuantity) {
          _.set(item, 'unmatched', true)
        } else {
          _.set(item, 'unmatched', false)
        }
        if (_.get(item, 'counts.length', 0) === 1) {
          _.set(item, 'countOnce', true)
        } else {
          _.set(item, 'countOnce', false)
        }
        if (_.get(item, 'counts.length', 0) === 2) {
          _.set(item, 'countTwice', true)
        } else {
          _.set(item, 'countTwice', false)
        }
      }
      function filterMatchType(item, productFilters) {
        if (productFilters.showMatchedProducts && item.matched) return true
        if (productFilters.showUnmatchedProducts && item.unmatched) return true
        if (productFilters.showCountingProducts && item.counting) return true
        return false
      }
  
      function filterCountTime(item,productFilters) {
        if (productFilters.showCountingProducts && item.counting) return true
        if (productFilters.showCountOnce && item.countOnce) return true
        if (productFilters.showCountTwice && item.countTwice) return true
        return false
      }
  
      function filterProductType(item, productFilters) {
        if (!productFilters.showFreezing && !productFilters.showFood && !productFilters.showNonFood) return true
        if (['Tủ đông', 'Tủ mát'].includes(item.warehouseGroup)) {
          return productFilters.showFreezing
        }
        if (item.categoryGroup === 'FOOD') {
          return productFilters.showFood
        } else {
          return productFilters.showNonFood
        }
      }
      function getProductFilter() {
        const productFilters = $localStorage.getObject('productFilters')
        if(productFilters && !_.isEmpty(productFilters)) return productFilters
        const defaultProductFilters = {
          showUnmatchedProducts: true,
          showMatchedProducts: false,
          showCountingProducts: true,
          showCountOnce: true,
          showCountTwice: true,
          showFreezing: false,
          showFood: false,
          showNonFood: false,
        }
        $localStorage.setObject('productFilters', defaultProductFilters)
        return defaultProductFilters
      }
      return {
        getInventoryCounts: () => {
          const user = Authentication.user
          const outlet = _.includes(user.roles, 'admin')
            ? user.outlet
              ? user.outlet._id || user.outlet
              : null
            : null

          return $http
            .get(RemoteServer.getRemoteServerAddress() + '/api/inventory-counts', {
              params: { outlet, userId: user._id },
            })
            .then((response) => response.data)
            .catch((err) => {
              ErrorHandler.alertServerErrorMessage(err)
              $state.go('app.welcome')
            })
        },
        getInventoryCount: (inventoryCountId, softItem) => {
          const user = Authentication.user
          inventoryCountPromise = $http
            .get(
              RemoteServer.getRemoteServerAddress() + '/api/inventory-counts/' + inventoryCountId,
              {
                params: { userId: user._id },
              }
            )
            .then((response) => {
              if (softItem) {
                const setTypeGroupLevel = (data) => {
                  /*
                      1 = Hàng lạnh
                      2 = Food
                      3 = Non-food
                    */
                  const items = data || []
                  return _.map(items, (item) => {
                    item.typeGroupLevel = 3

                    if (_.get(item, 'categoryGroup') === 'FOOD') {
                      item.typeGroupLevel = 2
                    }

                    if (
                      _.get(item, 'warehouseGroup') === 'Tủ đông' ||
                      _.get(item, 'warehouseGroup') === 'Tủ mát'
                    ) {
                      item.typeGroupLevel = 1
                    }

                    return item
                  })
                }
                const inventoryCount = response.data
                inventoryCount.items = _.sortBy(setTypeGroupLevel(inventoryCount.items), [
                  'typeGroupLevel',
                  'description',
                ])
                return inventoryCount
              }
              return response.data
            })
            .catch((err) => {
              ErrorHandler.alertServerErrorMessage(err)
              $state.go('app.welcome')
            })
          return inventoryCountPromise
        },
        getCurrentInventoryCount: () => inventoryCountPromise,
        getProductFilter: () => getProductFilter(),
        setItemType: (item) => setItemType(item),
        filterProducts: (item) => {
          let productFilters = getProductFilter()
          setItemType(item)
          return filterMatchType(item, productFilters) && filterCountTime(item, productFilters) && filterProductType(item, productFilters)
        }
      }
    },
  ])
