'use strict'

angular.module('customers').factory('CustomerService', [
  '$http',
  'RemoteServer',
  'ErrorHandler',
  function ($http, RemoteServer, ErrorHandler) {
    let customer
    // HACK debugging things
    const debug = _.curry((tag, x) => {
      return x
    })
    return {
      searchCustomers: (searchText) =>
        $http
          .get(RemoteServer.getRemoteServerAddress() + '/api/customers/search', {
            params: { searchText },
          })
          .then((response) => response.data)
          .then(debug('data'))
          .catch(ErrorHandler.alertServerErrorMessage),
    }
  },
])
