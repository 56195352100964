angular.module('stocks').controller('ProductListController', [
  '$scope',
  'products',
  'SlugService',
  '$ionicModal',
  function ($scope, products, SlugService, $ionicModal) {
    const vm = this
    vm.typeAheadProducts = []
    vm.products = products
    vm.productSearchChanged = function (text) {
      //Return if user only type in 2 character or less
      if (!vm.productSearch || vm.productSearch.length <= 2) return
      var productLatinDescription = SlugService.getSlug(vm.productSearch).trim()
      let elements = productLatinDescription.split(' ')

      $ionicModal
        .fromTemplateUrl('templates/stocks/product-modal.template.html', {
          scope: $scope,
          animation: 'slide-in-up',
        })
        .then((modal) => {
          vm.modal = modal
        })

      vm.showProduct = (product) => {
        vm.currentProduct = product
        vm.modal.show()
      }

      vm.closeModal = () => {
        vm.modal.hide()
      }

      vm.clearProductSearch = () => {
        vm.productSearch = ''
      }

      var products = _(vm.products)
        .map((product) => {
          let score = _.sumBy(elements, (elm) => {
            return _.includes(product.latinDescription, elm) ? elm.length : 0
          })

          if (_.includes(product.latinDescription, productLatinDescription))
            score = score + productLatinDescription.length

          let sku = (product.sku || '').toString().toLowerCase()

          let barcode = (product.barcode || '').toString().toLowerCase()

          if (_.includes(sku, productLatinDescription))
            score = score + productLatinDescription.length * 4
          if (_.includes(barcode, productLatinDescription))
            score = score + productLatinDescription.length * 3
          if (_.includes(barcode, productLatinDescription))
            score = score + productLatinDescription.length * 2

          return _.assign({}, product, { score })
        })
        .filter((product) => product.score > 0)
        .orderBy(['score'], ['desc'])
        .take(50)
        .value()
      vm.typeAheadProducts = products
    }
  },
])
