'use strict'

angular.module('core').directive('focusMe', [
  '$timeout',
  '$rootScope',
  '$window',
  function ($timeout, $rootScope, $window) {
    return {
      link: function (scope, element, attrs, ctrl) {
        const a = $rootScope.$on('$ionicView.afterEnter', function (event, data) {
          if (data.stateName === 'app.inventoryCount.count') {
            if (attrs.focusMeDisable === 'true') {
              return
            }
            $timeout(function () {
              element[0].focus()
              if (_.get($window, 'cordova.plugins.Keyboard')) {
                $window.cordova.plugins.Keyboard.show()
              }
            }, 350)
          }
        })
        scope.$on('$destroy', a)
      },
    }
  },
])
