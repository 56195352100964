'use strict'

angular.module('inventory-counts').controller('FirstCountController', [
  '$scope',
  '$state',
  '$ionicHistory',
  '$timeout',
  function ($scope, $state, $ionicHistory, $timeout) {
    let vm = this
    vm.inventoryCount = $scope.inventoryCount

    vm.isItemCounted = (item) => {
      if (!item || !_.isFinite(item.countQuantity)) {
        return false
      }
      if (item.inStock) {
        return true
      }
      return (
        _.isFinite(item.countQuantity) &&
        (item.countQuantity === item.expectedQuantity || _.get(item, 'counts.length') >= 2)
      )
    }

    $scope.$on('$ionicView.beforeEnter', () => {
      const firstUncountedItemIndex = _.findIndex(
        vm.inventoryCount.items,
        (item) => !vm.isItemCounted(item)
      )
      const firstUncountedItem = vm.inventoryCount.items[firstUncountedItemIndex]
      if (firstUncountedItem) {
        $ionicHistory.nextViewOptions({
          disableBack: true,
          historyRoot: true,
        })
        $state.go('app.inventoryCount.count', {
          inventoryCountId: vm.inventoryCount._id,
          productId: firstUncountedItem.product,
        })
      } else {
        vm.countingDone = true
        $timeout(() => {
          const firstItem = vm.inventoryCount.items[0]
          $state.go('app.inventoryCount.count', {
            inventoryCountId: vm.inventoryCount._id,
            productId: firstItem.product,
          })
        }, 3000)
      }
    })
  },
])
