'use strict'

angular.module('sale-entries').controller('CreateSaleEntryTabsController', [
  '$scope',
  function($scope) {
    $scope.data = {
      saleTotal: 0,
      items: [],
      customer: null,
    }
  },
])
