'use strict'

angular
  .module('core', [])

  .factory('$localStorage', [
    '$window',
    function($window) {
      return {
        set: function(key, value) {
          $window.localStorage[key] = value
        },
        get: function(key) {
          return $window.localStorage[key]
        },
        setObject: function(key, value) {
          $window.localStorage[key] = JSON.stringify(value)
        },
        getObject: function(key) {
          var value = $window.localStorage[key]
          if (!value || value === 'undefined' || value === 'null') return null
          try {
            return JSON.parse($window.localStorage[key] || null)
          } catch (e) {
            return null
          }
        },
        reset: function() {
          angular.forEach($window.localStorage, function(value, key) {
            $window.localStorage[key] = null
          })
        },
      }
    },
  ])
