'use strict'

angular
  .module('sale-entries')

  .config([
    '$stateProvider',
    function($stateProvider) {
      $stateProvider
        .state('app.createSaleEntry', {
          url: '/sale-entries/create',
          abstract: true,
          views: {
            menuContent: {
              templateUrl: 'templates/sale-entries/create/create-sale-entry-tabs.view.html',
              controller: 'CreateSaleEntryTabsController',
              controllerAs: 'vm',
            },
          },
          resolve: {
            products: [
              'ProductService',
              function(ProductService) {
                return ProductService.loadAvailable()
              },
            ],
          },
        })
        .state('app.createSaleEntry.search', {
          url: '/search',
          views: {
            'create-sale-entry-search': {
              templateUrl: 'templates/sale-entries/create/create-sale-entry-search.view.html',
              controller: 'CreateSaleEntryController',
              controllerAs: 'vm',
            },
          },
        })
        .state('app.createSaleEntry.order', {
          url: '/order',
          views: {
            'create-sale-entry-order': {
              templateUrl: 'templates/sale-entries/create/create-sale-entry-order.view.html',
              controller: 'CreateSaleEntryController',
              controllerAs: 'vm',
            },
          },
        })
        .state('app.createSaleEntry.customer', {
          url: '/customer',
          views: {
            'create-sale-entry-customer': {
              templateUrl: 'templates/sale-entries/create/create-sale-entry-customer.view.html',
              controller: 'CreateSaleEntryController',
              controllerAs: 'vm',
            },
          },
        })
    },
  ])
