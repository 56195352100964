'use strict'

angular.module('inventory-counts').controller('CountItemController', [
  '$scope',
  '$stateParams',
  '$http',
  '$state',
  '$window',
  'RemoteServer',
  '$ionicViewSwitcher',
  '$ionicHistory',
  'ErrorHandler',
  'Authentication',
  '$ionicPopup',
  '$ionicModal',
  'inventoryLocations',
  'InventoryLocations',
  'InventoryCounts',
  function (
    $scope,
    $stateParams,
    $http,
    $state,
    $window,
    RemoteServer,
    $ionicViewSwitcher,
    $ionicHistory,
    ErrorHandler,
    Authentication,
    $ionicPopup,
    $ionicModal,
    inventoryLocations,
    InventoryLocations,
    InventoryCounts
  ) {
    let vm = this
    vm.inventoryCount = $scope.inventoryCount

    vm.goNextItem = () => $state.go('app.inventoryCount.firstCount')

    vm.isItemCounted = (item) => {
      if (!item) {
        return false
      }
      if (item.inStock) {
        return true
      }
      return (
        _.isFinite(item.countQuantity) &&
        (item.countQuantity === item.expectedQuantity || _.get(item, 'counts.length') >= 2)
      )
    }

    vm.isItemNotUpdateInStock = (item) => {
      if (!vm.inventoryCount.name.match(/missing/gim)) {
        return false
      }
      return !item.inStock || _.get(item, 'counts.length', 0) < 2
    }

    const canGoNext = (countObject, item) => {
      if (
        (countObject.countQuantity !== countObject.expectedQuantity && item.counts.length >= 2) ||
        countObject.countQuantity === countObject.expectedQuantity
      )
        return true
      return false
    }

    vm.reset = () => {
      const confirmPopup = $ionicPopup.confirm({
        title: 'Bạn chắc chưa',
        template:
          'Bạn chắc chắn muốn yêu cầu kiểm lại ' +
          vm.item.sku +
          ' ' +
          vm.item.description +
          ' chứ?',
      })

      confirmPopup.then((res) => {
        if (res) {
          vm.resetting = true
          $http
            .post(
              RemoteServer.getRemoteServerAddress() +
              '/api/inventory-counts/' +
              vm.inventoryCount._id +
              '/reset',
              {
                product: vm.item.product,
                countQuantity: parseFloat(vm.countQuantity),
                user: Authentication.user,
                userId: Authentication.user._id,
              }
            )
            .then((res) => {
              $ionicPopup.alert({
                title: 'Đã gửi',
                template:
                  'Đã gửi yêu cầu kiểm lại ' + vm.item.sku + ' ' + vm.item.description + '.',
              })
              if (res.data.status === 'reset ok') {
                $window.location.reload()
              }
              vm.resetted = true
            })
            .catch(ErrorHandler.alertServerErrorMessage)
            .finally(() => {
              vm.resetting = false
            })
        }
      })
    }

    vm.updateInStock = () => {
      const confirmPopup = $ionicPopup.confirm({
        title: 'Bạn chắc chưa',
        template:
          'Bạn chắc chắn muốn cập nhật sản phẩm ' +
          vm.item.sku +
          ' ' +
          vm.item.description +
          'thành còn hàng chứ?',
      })

      confirmPopup.then((res) => {
        if (res) {
          vm.waiting = true
          $http
            .post(
              RemoteServer.getRemoteServerAddress() +
              '/api/inventory-counts/' +
              vm.inventoryCount._id +
              '/in-stock',
              {
                product: vm.item.product,
                user: Authentication.user,
                userId: Authentication.user._id,
              }
            )
            .then((res) => {
              $ionicPopup.alert({
                title: 'Đã cập nhật',
                template:
                  'Đã cập nhật sku ' + vm.item.sku + ' ' + vm.item.description + ' thành còn hàng.',
              })
              vm.goItem('next')
            })
            .catch(ErrorHandler.alertServerErrorMessage)
            .finally(() => {
              vm.waiting = false
            })
        }
      })
    }

    vm.updateOutStock = () => {
      const confirmPopup = $ionicPopup.confirm({
        title: 'Bạn chắc chưa',
        template:
          'Bạn chắc chắn muốn cập nhật sản phẩm ' +
          vm.item.sku +
          ' ' +
          vm.item.description +
          'thành hết hàng chứ?',
      })

      confirmPopup.then((res) => {
        if (res) {
          vm.waiting = true
          $http
            .post(
              RemoteServer.getRemoteServerAddress() +
              '/api/inventory-counts/' +
              vm.inventoryCount._id +
              '/out-stock',
              {
                product: vm.item.product,
                countQuantity: 0,
                user: Authentication.user,
                userId: Authentication.user._id,
              }
            )
            .then((res) => {
              $ionicPopup.alert({
                title: 'Đã cập nhật',
                template:
                  'Đã cập nhật sku ' + vm.item.sku + ' ' + vm.item.description + ' thành hết hàng.',
              })
              vm.goItem('next')
            })
            .catch(ErrorHandler.alertServerErrorMessage)
            .finally(() => {
              vm.waiting = false
            })
        }
      })
    }
    function countMobile({
      inventoryCount,
      item,
      countQuantity,
      user,
      needNotify = false,
      checkedItem,
    }) {
      let inventoryCountPromise = (inventoryCountPromise = $http
        .post(
          RemoteServer.getRemoteServerAddress() +
          '/api/inventory-counts/' +
          inventoryCount._id +
          '/count',
          {
            product: item.product,
            countQuantity: parseFloat(countQuantity),
            user: user,
            userId: user._id,
            needNotify,
            checkedItem,
          }
        )
        .then((response) => {
          const { countObject, skipNext } = response.data
          item.counts = item.counts || []
          item.counts.push(countObject)
          if (skipNext) {
            item.counts.push(countObject)
          }
          _.assign(item, countObject)
          countQuantity = null

          if (canGoNext(countObject, item)) {
            vm.goItem('next')
          }
        })

        .catch(ErrorHandler.alertServerErrorMessage))
      return inventoryCountPromise
    }
    vm.count = () => {
      if (!isNaN(vm.countQuantity) || _.isNumber(vm.countQuantity))
        $http
          .post(
            RemoteServer.getRemoteServerAddress() +
            '/api/inventory-counts/' +
            vm.inventoryCount._id +
            '/pre-count',
            {
              product: vm.item.product,
              countQuantity: parseFloat(vm.countQuantity),
              user: Authentication.user,
              userId: Authentication.user._id,
            }
          )
          .then((res) => {
            let { checkedItem } = res.data
            let needNotify = false
            let sendRequest = true
            if (checkedItem.length) {
              checkedItem = _.head(checkedItem)
              const buyingEntriesInfo = _.reduce(
                checkedItem.buyingEntries,
                (result, buyingEntry) => {
                  const { buyingEntryId, arrivingQuantity, dueDate } = buyingEntry
                  result += `\n - BE ${buyingEntryId} số lượng ${arrivingQuantity} dự kiến về ${moment(
                    dueDate
                  ).format('DD/MM/YYYY')} `
                  return result
                },
                ''
              )

              if (vm.item.counts.length >= 1) {
                sendRequest = $window.confirm(
                  `Sản phẩm ${vm.item.sku} ${vm.item.description}  có tổng số lượng ${checkedItem.arrivingQuantity} trong các Direct Buying Entry: ${buyingEntriesInfo} \nchưa được confirm, có khả năng bị trùng với số đang update.\nBạn có xác nhận muốn update sửa số?`
                )
                needNotify = sendRequest
              }
            }
            if (sendRequest) {
              countMobile({
                inventoryCount: vm.inventoryCount,
                item: vm.item,
                user: Authentication.user,
                countQuantity: vm.countQuantity,
                needNotify,
                checkedItem,
              })
            }
          })
          .catch(ErrorHandler.alertServerErrorMessage)
    }

    vm.inventoryLocations = inventoryLocations
    vm.searchInventoryLocations = vm.inventoryLocations
    $ionicModal
      .fromTemplateUrl('templates/select-inventory-location.modal.html', {
        scope: $scope,
        animation: 'slide-in-up',
      })
      .then((modal) => {
        vm.selectInventoryLocationModal = modal
      })

    vm.showSelectInventoryLocationModal = () => {
      vm.selectInventoryLocationModal.show()
    }

    vm.closeSelectInventoryLocationModal = () => {
      vm.selectInventoryLocationModal.hide()
    }

    vm.clearInventoryLocationSearch = () => {
      vm.inventoryLocationSearch = ''
    }

    vm.inventoryLocationSearchChanged = (text) => {
      vm.searchInventoryLocations = inventoryLocations.filter((inventoryLocation) => {
        const lowercase = text.toLowerCase().replace(/-/g, '')
        return inventoryLocation.replace(/-/g, '').toLowerCase().includes(lowercase)
      })
    }

    vm.changeInventoryLocation = (inventoryLocation) => {
      InventoryLocations.editLocation(vm.inventoryCount._id, vm.item.product, inventoryLocation)
        .then((data) => {
          vm.item.inventoryLocation = inventoryLocation
          vm.closeSelectInventoryLocationModal()
        })
        .catch(ErrorHandler.alertServerErrorMessage)
    }

    vm.toggleWrongLocation = (isWrong) => {
      $http
        .post(
          RemoteServer.getRemoteServerAddress() +
          '/api/inventory-counts/' +
          vm.inventoryCount._id +
          '/toggle-wrong-location',
          {
            product: vm.item.product,
            wrongLocation: isWrong,
            user: Authentication.user,
            userId: Authentication.user._id,
          }
        )
        .then((response) => {
          _.set(vm.item, 'wrongLocation', _.get(response, 'data.isWrong', isWrong))
        })
        .catch((error) => {
          ErrorHandler.alertServerErrorMessage(error)
          _.set(vm.item, 'wrongLocation', !isWrong)
        })
    }
    vm.filterProducts = InventoryCounts.filterProducts
    const getNextIndexMatchFilter = (currentItemIndex) => {
      const nextItemIndexMatchFilter = _.findIndex(vm.inventoryCount.items, (item) => { return vm.filterProducts(item) }, currentItemIndex + 1)
      if (nextItemIndexMatchFilter > -1) {
        return nextItemIndexMatchFilter
      }
      const firstItemIndexMatchFilter = _.findIndex(vm.inventoryCount.items, (item) => { return vm.filterProducts(item) })
      return firstItemIndexMatchFilter > -1 ? firstItemIndexMatchFilter : currentItemIndex
    }
    const getPreviousIndexMatchFilter = (currentItemIndex) => {
      const previousItemIndexMatchFilter = _.findLastIndex(vm.inventoryCount.items,  (item) => { return vm.filterProducts(item) }, currentItemIndex - 1)
      if (previousItemIndexMatchFilter > -1) {
        return previousItemIndexMatchFilter
      }
      const lastItemIndexMatchFilter = _.findLastIndex(vm.inventoryCount.items, (item) => { return vm.filterProducts(item) })
      return lastItemIndexMatchFilter > -1 ? lastItemIndexMatchFilter : currentItemIndex
    }
    vm.goItem = function (direction) {
      const itemIndex = _.findIndex(
        vm.inventoryCount.items,
        (item) => item.product === vm.item.product
      )
      const itemGo =
        direction === 'next'
          ? getNextIndexMatchFilter(itemIndex)
          : getPreviousIndexMatchFilter(itemIndex)
      if (direction === 'next') $ionicViewSwitcher.nextDirection('forward')
      else $ionicViewSwitcher.nextDirection('back')

      $ionicHistory.nextViewOptions({
        disableBack: true,
        historyRoot: true,
      })

      $state.go('app.inventoryCount.count', {
        inventoryCountId: vm.inventoryCount._id,
        productId: vm.inventoryCount.items[itemGo].product,
      })
    }

    $scope.$on('$ionicView.enter', (e, v) => {
      const productId = $stateParams.productId
      const itemIndex = _.findIndex(vm.inventoryCount.items, (item) => item.product === productId)
      // eslint-disable-next-line
      $scope.item = vm.item = vm.inventoryCount.items[itemIndex]

      $http
        .get(RemoteServer.getRemoteServerAddress() + '/api/inventory-counts/check-confirm-le', {
          params: { itemId: vm.item._id, outlet: Authentication.user.outlet._id },
        })
        .then((response) => {
          vm.notLEConfirm = response.data
        })
        .catch((err) => {
          ErrorHandler.alertServerErrorMessage(err)
        })

      $scope.item.needDot = !!_.get($scope.item, 'description', '').match(/mozzarella/i)
    })
  },
])
