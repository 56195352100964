angular.module('stocks').config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider.state('app.updateStock', {
      url: '/product-info',
      views: {
        menuContent: {
          templateUrl: 'templates/stocks/product-list.view.html',
          controller: 'ProductListController',
          controllerAs: 'vm',
        },
      },
      resolve: {
        products: [
          'ProductService',
          function (ProductService) {
            return ProductService.loadAvailable()
          },
        ],
      },
    })
  },
])
