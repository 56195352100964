'use strict'

angular.module('inventory-counts').controller('InventoryCountProductsController', [
  '$scope',
  'InventoryCounts',
  '$localStorage',
  function ($scope, InventoryCounts, $localStorage) {
    let vm = this

    vm.isItemCounted = (item) => {
      if (!item || !_.isFinite(item.countQuantity)) {
        return false
      }
      if (item.inStock) {
        return true
      }
      return (
        _.isFinite(item.countQuantity) &&
        (item.countQuantity === item.expectedQuantity || _.get(item, 'counts.length') >= 2)
      )
    }

    vm.isCounted = (item) => _.isFinite(item.countQuantity)
    let productFilters = InventoryCounts.getProductFilter()
    vm.inventoryCount = $scope.inventoryCount
    vm.items = vm.inventoryCount ? vm.inventoryCount.items : []
    vm.showUnmatchedProducts = productFilters.showUnmatchedProducts 
    vm.showMatchedProducts = productFilters.showMatchedProducts 

    vm.showCountingProducts = productFilters.showCountingProducts 
    vm.showCountOnce = productFilters.showCountOnce 
    vm.showCountTwice = productFilters.showCountTwice 
    vm.showFreezing = productFilters.showFreezing 
    vm.showFood = productFilters.showFood 
    vm.showNonFood = productFilters.showNonFood 
    const setItemType = InventoryCounts.setItemType
    $scope.$on('$ionicView.enter', function (event, data) {
      _.forEach(vm.items, (item) => {
        setItemType(item)
      })
    })

    vm.clearProductSearch = () => {
      vm.productSearch = {}
    }
    $scope.$watch(function () {
      return {
        showMatchedProducts: vm.showMatchedProducts,
        showUnmatchedProducts: vm.showUnmatchedProducts,
        showCountingProducts: vm.showCountingProducts,
        showCountOnce: vm.showCountOnce,
        showCountTwice: vm.showCountTwice,
        showFreezing: vm.showFreezing,
        showFood: vm.showFood,
        showNonFood: vm.showNonFood,
      }
    } , (object, oldObject) => {
        $localStorage.setObject('productFilters', object)
    }, true)
    vm.filterProducts = InventoryCounts.filterProducts
  },
])
