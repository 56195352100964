'use strict'

angular.module('core').factory('ErrorHandler', [
  function () {
    const getServerErrorMessage = (err) => {
      if (err.status === -1) return 'Không kết nối được với 4handy Work'
      if (err.status === 400)
        return (err.data ? err.data.message : err.message) || 'Dữ liệu gửi đi không hợp lệ'
      if (err.status === 403)
        return (err.data ? err.data.message : err.message) || 'Bạn không có quyển truy cập'
      if (err.status === 404)
        return (err.data ? err.data.message : err.message) || 'Server không có đường dẫn này'
      if (err.status === 500) {
        return (err.data ? err.data.message : err.message) || 'Trang 4handy Work đang lỗi'
      }
      return 'Lỗi không xác định: ' + JSON.stringify(err)
    }

    const alertServerErrorMessage = (err) => {
      const message = getServerErrorMessage(err)
      alert(message)
    }

    const alertMessage = (message) => {
      alert(message)
    }

    return {
      getServerErrorMessage,
      alertServerErrorMessage,
      alertMessage,
    }
  },
])
