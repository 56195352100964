'use strict'

angular.module('products').factory('ProductService', [
  '$http',
  'RemoteServer',
  'ErrorHandler',
  'SlugService',
  '$filter',
  function ($http, RemoteServer, ErrorHandler, SlugService, $filter) {
    let products
    const getSlug = SlugService.getSlug

    const attachFullDescription = function (products) {
      return _.map(products, (product) =>
        _.assign({}, product, {
          latinDescription: getSlug(product.description),
        })
      )
    }

    const load = () => {
      if (!products) {
        products = $http
          .get(RemoteServer.getRemoteServerAddress() + '/api/products', {
            params: {
              select: 'sku description imageLink price',
              deleted: false,
              toSell: true,
            },
          })
          .then((response) => response.data)
          .then(attachFullDescription)
          .catch(ErrorHandler.alertServerErrorMessage)
      }
      return products
    }
    return {
      loadAvailable: load,
    }
  },
])
