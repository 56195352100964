'use strict'

angular
  .module('starter.controllers', [])

  .controller('AppCtrl', [
    '$scope',
    '$ionicModal',
    '$timeout',
    'Authentication',
    'AuthService',
    function($scope, $ionicModal, $timeout, Authentication, AuthService) {
      $scope.user = Authentication.user

      // With the new view caching in Ionic, Controllers are only called
      // when they are recreated or on app start, instead of every page change.
      // To listen for when this page is active (for example, to refresh data),
      // listen for the $ionicView.enter event:
      //$scope.$on('$ionicView.enter', function(e) {
      //});

      // Form data for the login modal
      // Create the login modal that we will use later

      $ionicModal.fromTemplateUrl('templates/login.html').then(function(modal) {
        $scope.modal = modal
      })

      // Triggered in the login modal to close it
      $scope.closeLogin = function() {
        $scope.modal.hide()
      }

      // Open the login modal
      $scope.login = function() {
        $scope.modal.show()
      }

      $scope.logout = AuthService.signout
    },
  ])

  .controller('PlaylistsCtrl', function($scope) {
    $scope.playlists = [
      { title: 'Reggae', id: 1 },
      { title: 'Chill', id: 2 },
      { title: 'Dubstep', id: 3 },
      { title: 'Indie', id: 4 },
      { title: 'Rap', id: 5 },
      { title: 'Cowbell', id: 6 },
    ]
  })

  .controller('PlaylistCtrl', function($scope, $stateParams) {})
