'use strict'

angular
  .module('inventory-counts')

  .config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('app.listInventoryCounts', {
          url: '/inventory-counts',
          views: {
            menuContent: {
              templateUrl: 'templates/list-inventory-counts.html',
              controller: 'InventoryCountsController',
              controllerAs: 'vm',
            },
          },
        })
        .state('app.inventoryCount', {
          url: '/inventory-counts/:inventoryCountId',
          abstract: true,
          cache: false,
          views: {
            menuContent: {
              templateUrl: 'templates/inventory-count-tabs.html',
              controller: 'InventoryCountTabsController',
              controllerAs: 'vm',
            },
          },
          resolve: {
            inventoryCount: [
              'InventoryCounts',
              '$stateParams',
              function (InventoryCounts, $stateParams) {
                return InventoryCounts.getInventoryCount($stateParams.inventoryCountId, true)
              },
            ],
            inventoryLocations: [
              'InventoryLocations',
              function (InventoryLocations) {
                return InventoryLocations.getInventoryLocationCodes()
              },
            ],
          },
        })
        .state('app.inventoryCount.info', {
          url: '/info',
          views: {
            'inventory-count-info': {
              templateUrl: 'templates/inventory-count-info.html',
              controller: 'InventoryCountInfoController',
              controllerAs: 'vm',
            },
          },
        })
        .state('app.inventoryCount.products', {
          url: '/products',
          views: {
            'inventory-count-products': {
              templateUrl: 'templates/inventory-count-products.html',
              controller: 'InventoryCountProductsController',
              controllerAs: 'vm',
            },
          },
        })
        .state('app.inventoryCount.firstCount', {
          url: '/count',
          views: {
            'inventory-count-count': {
              templateUrl: 'templates/first-count.view.html',
              controller: 'FirstCountController',
              controllerAs: 'vm',
            },
          },
        })
        .state('app.inventoryCount.count', {
          url: '/count/:productId',
          views: {
            'inventory-count-count': {
              templateUrl: 'templates/inventory-count-count.html',
              controller: 'CountItemController',
              controllerAs: 'vm',
            },
          },
        })
    },
  ])
