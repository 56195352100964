'use strict'

angular.module('core').factory('SlugService', [
  function() {
    var slug = createSlug({ lang: 'vn', separator: ' ', custom: { ',': '' } })
    return {
      getSlug: function(str) {
        return slug(str)
      },
    }
  },
])
