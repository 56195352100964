'use strict'

angular.module('inventory-counts').controller('InventoryCountTabsController', [
  'InventoryCounts',
  '$scope',
  '$ionicPopup',
  'inventoryCount',
  function(InventoryCounts, $scope, $ionicPopup, inventoryCount) {
    let vm = this
    $scope.inventoryCount = vm.inventoryCount = inventoryCount
  },
])
