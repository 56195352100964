'use strict'

angular.module('inventory-counts').controller('InventoryCountsController', [
  '$scope',
  'InventoryCounts',
  '$stateParams',
  '$location',
  '$ionicHistory',
  '$ionicPopup',
  'ErrorHandler',
  function (
    $scope,
    InventoryCounts,
    $stateParams,
    $location,
    $ionicHistory,
    $ionicPopup,
    ErrorHandler
  ) {
    let vm = this

    vm.inventoryCounts = []

    $ionicPopup.alert({
      title: 'Lưu ý kiểm hàng',
      template: `
- Các mặt hàng đang giữ cho Online Order cần tạo đơn trước khi kiểm<br>
- Kiểm cả số lượng các sku khách đang giữ chờ thanh toán<br>
- Trong quá trình kiểm hàng, nếu có các mặt hàng hỏng đang chờ thu hồi, hết date chưa có LE vẫn phải kiểm. Kết thúc BKH mới tạo đơn hủy, thu hồi<br>
- Không ấn nhận Logistic Entry, Direct Buying có chứa các sku đang được kiểm`,
    })

    vm.find = function () {
      InventoryCounts.getInventoryCounts()
        .then((inventoryCounts) => {
          vm.inventoryCounts = inventoryCounts
        })
        .catch(ErrorHandler.alertServerErrorMessage)
        .finally(() => $scope.$broadcast('scroll.refreshComplete'))
    }

    vm.inventoryCount = {}
  },
])
