'use strict'

angular.module('inventory-counts').controller('InventoryCountInfoController', [
  '$scope',
  function($scope) {
    let vm = this
    vm.inventoryCount = $scope.inventoryCount
    vm.getPercentage = (intPercentage) => {
      if(!intPercentage) {
        return "0%"
      }
      return Math.round(intPercentage * 100) + '%'
    }
  },
])
