'use strict'
const dependencies = [
  'ionic',
  'fhd-count',
  'inventory-counts',
  'starter.controllers',
  'authentication',
  'core',
  'sale-entries',
  'products',
  'customers',
  'stocks',
]

angular
  .module('fhd-count', dependencies)
  .run([
    '$ionicPlatform',
    'Authentication',
    '$location',
    function($ionicPlatform, Authentication, $location) {
      $ionicPlatform.ready(function() {
        // Hide the accessory bar by default (remove this to show the accessory bar above the keyboard
        // for form inputs)
        if (window.cordova && window.cordova.plugins.Keyboard) {
          cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true)
          cordova.plugins.Keyboard.disableScroll(true)
        }
        if (window.StatusBar) {
          // org.apache.cordova.statusbar required
          window.StatusBar.styleDefault()
        }

        // Add logic to redirect back to home if user not sign in yet
        if (!Authentication.user) {
          $location.path('/')
        }
      })
    },
  ])

  .config([
    '$stateProvider',
    '$urlRouterProvider',
    function($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('app', {
          url: '/app',
          abstract: true,
          templateUrl: 'templates/menu.html',
          controller: 'AppCtrl',
        })
        .state('app.welcome', {
          url: '/welcome',
          views: {
            menuContent: {
              templateUrl: 'templates/welcome.html',
            },
          },
        })

      // if none of the above states are matched, use this as the fallback
      $urlRouterProvider.otherwise('/app/welcome')
    },
  ])
