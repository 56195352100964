'use strict'

// Authentication service for user variables
angular
  .module('authentication', [])

  .factory('Authentication', [
    '$localStorage',
    function($localStorage) {
      var _this = this

      _this._data = {
        user: $localStorage.getObject('user'),
      }

      return _this._data
    },
  ])

  .factory('AuthService', [
    '$localStorage',
    '$window',
    function($localStorage, $window) {
      return {
        signout: function() {
          $localStorage.reset()
          $window.location.reload()
        },
      }
    },
  ])

  .controller('AuthenticationController', [
    '$scope',
    '$http',
    '$location',
    'Authentication',
    '$window',
    '$localStorage',
    'RemoteServer',
    'ErrorHandler',
    function(
      $scope,
      $http,
      $location,
      Authentication,
      $window,
      $localStorage,
      RemoteServer,
      ErrorHandler
    ) {
      let vm = this
      let authentication = Authentication
      vm.credentials = {}

      vm.signin = function() {
        $http
          .post(RemoteServer.getRemoteServerAddress() + '/auth/signin', vm.credentials)
          .then(function(response) {
            $localStorage.reset()
            // If successful we assign the response to the global user model
            $localStorage.setObject('user', response.data)
            authentication.user = response.data
            $window.location.reload()
          })
          .catch(function(response) {
            vm.error = ErrorHandler.getServerErrorMessage(response)
          })
      }
    },
  ])
