'use strict'

angular
  .module('inventory-counts')

  .factory('InventoryLocations', [
    '$http',
    'RemoteServer',
    'Authentication',
    'ErrorHandler',
    function ($http, RemoteServer, Authentication, ErrorHandler) {
      return {
        updateInventoryLocation: (productId, inventoryLocationCode) => {
          const user = Authentication.user
          return $http
            .post(RemoteServer.getRemoteServerAddress() + '/api/update-inventory-location', {
              productId,
              inventoryLocationCode,
              userId: user._id,
            })
            .then((response) => {
              return response.data
            })
        },
        editLocation: (inventoryCountId, productId, inventoryLocationCode) => {
          const user = Authentication.user

          return $http
            .put(
              RemoteServer.getRemoteServerAddress() +
                `/api/inventory-counts/${inventoryCountId}/editLocation`,
              {
                productId,
                inventoryLocationCode,
                userId: user._id,
              }
            )
            .then((response) => {
              return response.data
            })
        },
        getInventoryLocationCodes: () => {
          const user = Authentication.user
          return $http
            .get(RemoteServer.getRemoteServerAddress() + '/api/inventory-location-codes', {
              params: { userId: user._id },
            })
            .then((response) => {
              return response.data
            })
            .catch((err) => {
              ErrorHandler.alertServerErrorMessage(err)
            })
        },
      }
    },
  ])
