'use strict'

angular.module('core').factory('RemoteServer', [
  '$location',
  function ($location) {
    return {
      getRemoteServerAddress: () => {
        const port = $location.port()
        // Nếu truy cập bằng domain name thì port sẽ là port của http/https 80/443 nên sẽ ko lọt vào localhost, ôi thật hack não
        if (port === 8100 || port === 82) return 'http://localhost:3000'
        else return 'https://backend.4handy.vn'
      },
    }
  },
])
